import "./js/bcore_legacy/class"
import "./js/bcore_legacy/polyfill";
import "./js/bcore_legacy/provide";
import "./js/bcore_legacy/format";
import "./js/bcore_legacy/utils/number_format";
import "./js/bcore_legacy/utils/utils";
import "./js/bcore_legacy/utils/common";
import "./js/bcore_legacy/ui/messages";
import "./js/bcore_legacy/translate";
import "./js/bcore_legacy/utils/pretty_date";
import "./js/bcore_legacy/microtemplate";
import "./js/bcore_legacy/query_string";
import "./js/bcore_legacy/request";

import "./js/bcore_legacy/ui/keyboard";
import "./js/bcore_legacy/ui/dropzone";
import "./js/bcore_legacy/ui/upload.html";
import "./js/bcore_legacy/upload";

import "./js/bcore_legacy/model/meta";
import "./js/bcore_legacy/model/model";
import "./js/bcore_legacy/model/perm";

import "./js/bcore_legacy/link_title";

import "../website/js/website";
import "../website/js/bootstrap-4.js";
import "./js/bcore_legacy/utils/rating_icons.html";
